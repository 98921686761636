<template>
	<div id="login-page">
		<div class="fill-hw flex-row">
			<div class="flex-grow flex-column">
				<img class="splash-img" src="assets/splash/2.jpg" alt="">
			</div>
			<div class="width-500 flex-row">
				<div class="width-320 flex-column horizontal-flex-center margin-top-200">
					<div class="flex-row">
						<img class="big-icon" src="assets/logo/tdl.png" alt="">
						<h1 class="h1-title vertical-flex-center horizontal-flex-center">TDL</h1>
					</div>
					<h3 class="h3-title-secondary-color margin-top-1-05 horizontal-flex-center">Login to your account</h3>
					<form class="flex-column" @submit.prevent="login()">
						<base-text-field v-model="loginData.username" class="margin-top-1-05" title="Username" required/>
						<base-text-field v-model="loginData.password" class="margin-top" title="Password" type="password" required/>
						<primary-button type="submit" class="margin-top-2" title="Sign In" />
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTextField from '../../components/inputs/BaseTextField.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';

import { loginRequest } from "../../api/requests/Auth.js";

export default {
	name: "Login",
	components: {
		BaseTextField,
		PrimaryButton
	},
	data: function() {
		return {
			loginData: {
				username: "",
				password: "",
			}
		}
	},
	methods: {
		login: function() {
			loginRequest(this.data).then(response => {
				console.log(response);
			}).catch(error => {
				console.log(error);
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";

#login-page {
	height: 100vh;
	width: 100vw;
}

.fill-hw {
	height: 100%;
	width: 100%;
}
.splash-img {
	@extend .fill-hw;

	object-fit: cover;
}

.margin-top-200 {
	margin-top: 200px;
}
</style>