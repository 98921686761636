import { axiosInstance, endpoints } from "../managers/axiosHandler";

function loginRequest(loginData) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.login, loginData)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

// function logout() {
//   return new Promise((resolve, reject) => {
//     axiosInstance()
//       .post(apiConstants.api_auth_hostname + "rest-logout/")
//       .then(response => {
//         resolve(response?.data);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

export { loginRequest };
