<template>
	<div class="flex-column">
		<p class="text-field-title">{{ title }}</p>
		<input class="text-field margin-top-05" :type="type" :placeholder="getPlaceholder" :value="modelValue" @input="updateValue" :required="required">
	</div>
</template>

<script>
export default {
	name: "BaseTextField",
	props: {
		title: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "text"
		},
		modelValue: {
			type: String,
			default: ""
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updateValue(event) {
			this.$emit("update:modelValue", event.target.value);
		}
	},
	computed: {
		getPlaceholder: function() {
			return this.placeholder ? this.placeholder : this.title;
		}
	} 
}
</script>

<style lang="scss" scoped>
@import "../../scss/fields.scss";
</style>